<template>
  <div id="app">
    <main>
      <h1>Welcome to my portfolio</h1>
    </main>
  </div>
</template>

<script>
export default {
  name: "App",
  data: function () {
    return {
      title: "welcome to my portfolio",
    };
  },
  head: {
    title: function () {
      return { inner: this.title, complement: 'neyses.me' };
    }
  },
  
};
</script>

<style>
body,
html {
  background-color: #dddddd;
}

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
</style>
